<template>
    <contenWrap>
        <div class="storeAccountManager">
            <div class="tool-card">
                <div class="add-btn-wrap">
                    <CreateBtn  name='添加配件分类' @cbCreateBtnClick="handleCreateStore"></CreateBtn>
                </div>
            </div>
            <div class="table-card">
                <el-table
                    :data="tableData1"
                    style="width: 100%"
                    row-key="label"
                    :expand-row-keys="expandID"
                    lazy
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                >
                    <el-table-column label="分类名称" >
                        <template slot-scope="scope">
                          <div>{{scope.row.label}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建日期">
                        <template slot-scope="scope">
                          <div>{{scope.row.createTime}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="145px">
                      <template slot-scope="scope">
                        <el-button size="mini"
                          @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" 
                          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                      </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- 添加商品分类dialog -->
        <el-dialog
            title="添加分类"
            :visible.sync="isShowAddStoreDialog"
            width="650px"
            :close-on-click-modal="false"  
            class="addStoreDialog"
        >
            <div class="content">
                <el-form ref="createStoreFormRef" :rules="createStoreFormRules" :model="createStoreForm" label-width="100px">
                    <el-form-item label="上级分类" prop="id">
                        <el-cascader
                          v-model="createStoreForm.id"
                          :options="classifyOptions"
                          :props="{ checkStrictly: true }"
                          clearable></el-cascader>
                    </el-form-item>   
                    <el-form-item label="分类名称" prop="classifyName">
                        <el-input v-model="createStoreForm.classifyName"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="el-dialog__footer">
                <div class="dialog-footer-cancle" @click="resetForm">取 消</div>
                <div class="dialog-footer-submit" @click="submitFormEdit" v-if="isShowBnt">修改</div>
                <div class="dialog-footer-submit" @click="submitForm" v-else>新建</div>
            </div>
        </el-dialog>        
    </contenWrap>
</template>

<script>
import contenWrap from '@/components/contenWrap'
import pageMixin from '@/mixin/pageMixin'
import CreateBtn from '@/components/CreateBtn'
export default {
    mixins:[pageMixin],
    components: {
        contenWrap,
        CreateBtn
    },
    data() {
        return {
          ExpandedNum:0,
          expandID:[],
          tableData1:[],
          tableData: [],
          tabPosition: '1',
          classifyOptions: [],
          curId: null,
          pageMixin_index:'storeAccountManager',
          isShowAddStoreDialog:false,
          createStoreForm:{
              id: '',
              classifyName: '',
          },
          dialogImageUrl: '',
          dialogVisible: false,
          isShowBnt: false,
          createStoreFormRules: {
            classifyName: [
                { required: true, message: '请选择分类名称', trigger: 'blur' },
            ],
            classify: [
                { required: true, message: '请选择上级分类', trigger: 'change' }
            ]
          },
        }
    },
    watch:{
      ExpandedNum:{
        deep:true,
          handler(val){
              this.ExpandedNum = val
          }
      },
      ExpandedKeysCache:{
          deep:true,
          handler(val){
              this.ExpandedKeysCache = val
          }
      },
    },
    created() {
    },
    mounted(){
      this.getListCatalog();
    },
    methods: {
      // 修改
      submitFormEdit(){
        this.$refs.createStoreFormRef.validate((boolean)=>{
            if(!boolean){
                this.$message.warning('详情未完善')
            }else{
              let params = {
                id: this.curId,
                classifyName: this.createStoreForm.classifyName

              }
                this.$API.updateCatalogType(params).then( res => {
                    if(res.code === 0){
                        this.getListCatalog();
                        this.$message.success("修改分类成功！");
                        this.isShowAddStoreDialog = false;
                        this.$refs.createStoreFormRef.resetFields();
                    }
                })
            } 
        })
      },
      // 点击编辑
      handleEdit(index,row){
        let params = {
            id: row.value,
          }
          this.$API.getCatalogInfoType(params).then( res => {
              if(res.code === 0){
                this.createStoreForm.id = res.data.parentId;
                this.createStoreForm.classifyName = res.data.classifyName;
                this.curId = row.value,
                this.isShowBnt = true;
                this.isShowAddStoreDialog = true;
              }
          })
      },
      setExpandKeys(dataList, n){
          if(!n) n = 1
          for(let i = 0; i < dataList.length; i++){
              if(n <= this.ExpandedNum){
                  this.expandID.push(`${dataList[i].id}`)
                  if(dataList[i].hasOwnProperty('children')){
                      const children = dataList[i].children
                      this.setExpandKeys(children, n + 1)
                  }
              }
          }
      },
      //点击删除
      handleDelete(index,row){
        let ids = [];
        ids.push(row.value)
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            ids: ids,
          }
          this.$API.deleteCatalogType(params).then( res => {
              if(res.code === 0){
                this.getListCatalog();
                this.$message.success("删除分类成功")
              }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
      });
      },
      // 获取配件类目结构
      getListCatalog(){
        this.$API.getCatalogList().then( res => {
            if(res.code === 0){
                let resultSecond;
                var result = res.data.map(o=>{
                    if(o.childrenNode){
                        resultSecond =  o.childrenNode.map(c=>{
                            return{
                                value: c.data.id, 
                                label: c.data.classifyName,
                                createTime: c.data.createTime
                            }
                        })
                    }
                    return{
                        value: o.data.id, 
                        label: o.data.classifyName,
                        createTime: o.data.createTime,
                        children: resultSecond
                    }
                });
                //添加弹窗的选择
                var results = res.data.map(o=>{
                    if(o.childrenNode){
                        resultSecond =  o.childrenNode.map(c=>{
                            return{
                                value: c.data.id, 
                                label: c.data.classifyName,
                                createTime: c.data.createTime
                            }
                        })
                    }
                    return{
                        value: o.data.id, 
                        label: o.data.classifyName,
                        createTime: o.data.createTime,
                        // children: resultSecond
                    }
                });
                this.tableData1 = result;
                this.classifyOptions = results;
            }
        })
      },
      //提交添加分类
      submitForm() {
          this.$refs.createStoreFormRef.validate((valid) => {
          if (valid) {
            let parentId = this.createStoreForm.id
            let id = parentId[parentId.length-1]
            let params ={
                id: id,
                classifyName: this.createStoreForm.classifyName,
            }
            this.$API.addCatalogType(params).then( res => {
                if(res.code === 0){
                    this.isShowAddStoreDialog = false;
                    this.$refs.createStoreFormRef.resetFields();
                    this.getListCatalog();
                    this.$message.success("添加分类成功")
                }
            })
          } else {
              console.log('error submit!!');
              return false;
          }
          });
      },
      //取消
      resetForm() {
          this.$refs.createStoreFormRef.resetFields();
          this.isShowAddStoreDialog = false;
      },
      handleCreateStore(){
          this.isShowAddStoreDialog = true;
          this.isShowBnt = false;
          this.createStoreForm.id = '';
          this.createStoreForm.classifyName = '';
      }
    }
}
</script>

<style lang="scss">
.storeAccountManager{
    .tool-card{
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $card-border-color;
    }
    .table-card{
        margin-top: 20px;
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $card-border-color;    
        .imgae{
          width: 40px;
          height: 40px;
        }    
    }

}
.addStoreDialog{
    .content{
      padding: 0 20px;
      //修改开关按钮样式
      .tablescope-wrap{
        .el-switch{
          margin-left: -35px !important;
        }
        .el-switch__label--left {
            position: relative;
            left: 60px;
            color: #fff;
            z-index: -1111;
        }
        .el-switch__core{
            width: 60px !important;
        }
        .el-switch__label--right {
            position: relative;
            right: 60px;
            color: #fff;
            z-index: -1111;
        }
        .el-switch__label--right.is-active {
            z-index: 1111;
            color: #fff !important;
        }
        .el-switch__label--left.is-active {
            z-index: 1111;
            color: #9c9c9c !important;
        }
      }        
    }
}
.table-card{
  //修改开关按钮样式
  .tablescope-wrap{
    line-height: 0;
    
    .el-switch__label--left {
        position: relative;
        left: 60px;
        color: #fff;
        z-index: -1111;
    }
    .el-switch__core{
        width: 60px !important;
    }
    .el-switch__label--right {
        position: relative;
        right: 60px;
        color: #fff;
        z-index: -1111;
    }
    .el-switch__label--right.is-active {
        z-index: 1111;
        color: #fff !important;
    }
    .el-switch__label--left.is-active {
        z-index: 1111;
        color: #9c9c9c !important;
    }
  } 
  .el-table .cell{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>